<template>
  <v-card flat color="transparent" class="xw-full xmin-h-[100vh]">
    <v-card-title>
      <h1 class="xfont-[600] xtext-[24px] xleading-[29px]">
        Update Information
      </h1>
    </v-card-title>
    <v-card-text>
      <v-progress-linear
        indeterminate
        :height="10"
        class="my-2"
        v-if="fetching"
        color="primary"
      ></v-progress-linear>

      <v-card
        flat
        outlined
        v-if="defaultCard"
        class="xmy-[1em] xmx-auto xmax-w-[1366px]"
      >
        <v-card-title>Current Card</v-card-title>
        <v-card-text
          class="xflex md:xflex-row xflex-col md:xjustify-between xjustify-start md:xitems-center xitems-start xgap-[0.5em]"
        >
          <div class="xuppercase">
            Brand:
            <span class="xfont-bold">{{ defaultCard.card.brand }} </span>
          </div>
          <div class="xuppercase">
            Last4:
            <span class="xfont-bold">{{ defaultCard.card.last4 }} </span>
          </div>
          <div class="xuppercase">
            Expiration:
            <span class="xfont-bold">
              {{ defaultCard.card.exp_month }}/{{ defaultCard.card.exp_year }}
            </span>
          </div>
        </v-card-text>
        <!-- <pre>{{ defaultCard }}</pre> -->
      </v-card>

      <v-card
        flat
        class="xmy-[1em] xmx-auto xmax-w-[1366px] xgap-y-[1em]"
        v-if="!changeCard && defaultCard"
      >
        <v-btn
          color="primary"
          depressed
          class="text-none"
          @click="changeCard = !changeCard"
        >
          <v-icon left>mdi-credit-card-edit-outline</v-icon> Change Card
        </v-btn>
      </v-card>

      <v-card
        flat
        outlined
        class="xmy-[1em] xmx-auto xw-full xmax-w-[1366px]"
        v-if="changeCard"
      >
        <v-card-text
          class="xmx-auto xw-full xmax-w-[600px] xgap-y-[1em] md:xmin-h-[400px] xmin-h-auto xflex xflex-col xjustify-center xitems-center"
        >
          <v-form ref="form" v-model="isValid" class="xw-full">
            <div class="xflex xflex-col xw-full xmb-[1em]">
              <label class="xmb-[10px]">Email Address <sup>*</sup></label>
              <v-text-field
                type="email"
                flat
                outlined
                v-model="email"
                height="43px"
                hide-details="auto"
                :rules="[requiredRule(), emailRule()]"
                placeholder="Email"
                class="xtext-[#6a2c91]"
              ></v-text-field>
            </div>
            <div class="xflex xflex-col xw-full xmb-[1em]">
              <div class="xflex xflex-row xjustify-between xitems-center">
                <label class="xmb-[10px]">Card Details <sup>*</sup></label>
                <div
                  class="xflex xflex-row xjustify-start xitems-start xgap-[10px]"
                >
                  <v-avatar
                    size="25"
                    tile
                    :title="img.name"
                    v-for="(img, index) in cards"
                    :key="index"
                  >
                    <v-img contain :src="img.img" :alt="img.name"></v-img>
                  </v-avatar>
                </div>
              </div>
              <StripeElementCard
                v-if="publishableKey"
                ref="elementRef"
                :disabled="submitting"
                :pk="publishableKey"
                :token-data="{ email: email }"
                hide-postal-code
                :element-style="elementStyle"
                :elements-options="elementOptions"
              ></StripeElementCard>
              <small>
                Secured by
                <a href="https://stripe.com/" target="_blank">Stripe</a>
              </small>
            </div>
            <div
              class="xflex xflex-row xgap-[1em] xmt-[3em] xjustify-center xitems-center xw-full"
            >
              <v-btn
                color="#000"
                depressed
                dark
                class="text-none px-6"
                @click="changeCard = !changeCard"
              >
                <v-icon left>mdi-credit-card-refresh-outline</v-icon> Cancel
              </v-btn>
              <v-btn
                color="primary"
                depressed
                class="text-none px-6"
                @click="submit"
              >
                <v-icon left>mdi-credit-card-edit-outline</v-icon> Update Card
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>

      <app-empty
        class="xmin-h-[50vh]"
        v-if="!defaultCard && !fetching"
        text="Not yet subscribed"
      >
        <v-btn
          depressed
          dark
          color="primary"
          class="text-none xmt-[2em] px-6"
          @click="$router.go(-1)"
        >
          Back
        </v-btn>
      </app-empty>
    </v-card-text>
    <!-- <pre>{{ setupIntent }}</pre> -->
  </v-card>
</template>

<script>
import { StripeElementCard } from "@vue-stripe/vue-stripe";
import { mapGetters } from "vuex";
export default {
  components: {
    StripeElementCard,
  },
  data() {
    return {
      publishableKey: null,
      loading: false,
      isSubscribed: false,
      price_per_qty: 2,
      token: null,
      isValid: false,
      email: null,
      fetching: false,
      changeCard: false,
      payingMembers: [],
      setupIntent: null,
      defaultCard: null,
      submitting: false,
      cards: [
        { img: require("@/assets/cards/amex.png"), name: "American Express" },
        {
          img: require("@/assets/cards/diners-club.png"),
          name: "Discover & Diners",
        },
        {
          img: require("@/assets/cards/jcb.png"),
          name: "Japan Credit Bureau (JCB)",
        },
        { img: require("@/assets/cards/mastercard.svg"), name: "Mastercard" },
        {
          img: require("@/assets/cards/unionpay.svg"),
          name: "China UnionPay (CUP)",
        },
        { img: require("@/assets/cards/visa.svg"), name: "Visa" },
      ],
    };
  },
  computed: {
    ...mapGetters(["user"]),
    elementOptions() {
      return {
        appearance: {
          theme: "stripe",
        },
      };
    },
    elementStyle() {
      return {
        base: {
          iconColor: "#6a2c91",
          color: "#6a2c91",
          fontWeight: "600",
          fontFamily: "Inter, sans-serif",
          fontSize: "20px",
          fontSmoothing: "antialiased",
          ":-webkit-autofill": {
            color: "#6a2c91",
          },
          "::placeholder": {
            color: "#6a2c91",
          },
          lineHeight: "20px",
          padding: 20,
        },
        empty: {
          "::placeholder": {
            color: "#2e2e2e",
          },
          padding: 20,
          fontWeight: "400",
        },
        invalid: {
          iconColor: "#d01313",
          color: "#d01313",
        },
      };
    },
  },
  created() {
    if (!this.$auth.isAuthenticated()) {
      this.$router.push({
        name: "login",
        query: { redirect_uri: this.$route.fullPath },
      });
      return;
    }
    this.fetchInfo();
    this.resetValidation();
    this.email = this.user ? this.user.email : null;
  },
  methods: {
    fetchInfo() {
      this.fetching = true;
      this.$axios
        .post("api/subscription/payment/info", {})
        .then(({ data }) => {
          this.publishableKey = data.publishable_key;
          this.setupIntent = data.setup_intent;
          this.defaultCard = data.default_card;
        })
        .finally(() => (this.fetching = false));
    },
    async submit() {
      const stripe = this.$refs.elementRef.stripe;
      const card = this.$refs.elementRef.element;
      const billingDetails = {
        name: this.user.real_name || this.user.email,
        email: this.user.email,
      };
      const { setupIntent, error } = await stripe.confirmCardSetup(
        this.setupIntent.client_secret,
        {
          payment_method: {
            card: card,
            billing_details: billingDetails,
          },
        }
      );
      if (error) {
        this.appToast(error.message, "error");
        // Display "error.message" to the user...
      } else {
        // The card has been verified successfully...
        this.submitting = true;
        this.appLoader(true, "Processing card changes. Please wait...");
        this.$axios
          .put(`api/subscription/payment/info`, {
            payment_method: setupIntent.payment_method,
          })
          .then(({ data }) => {
            this.appToast("Successfully updated.", "success");
            this.$auth.fetchUser();
            this.$router.push({ name: "app-subscription" });
          })
          .finally(() => {
            this.appLoader(false);
            this.submitting = false;
          });
      }
    },
  },
};
</script>

<style lang="scss">
#stripe-cards {
  width: 100%;
  .v-messages__wrapper {
    .v-messages__message {
      color: #4a3e51 !important;
      font-size: 15px;
    }
  }
}
</style>
